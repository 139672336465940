

.jumbotron-fluid {
    background-color: white;
    margin:25px;
    box-shadow: 0px 10px 20px rgba(0,0,0,0.1);
    border-radius: 6px;
}

.jumbotron-fluid.shop {
    background-color: white;
    margin:25px;
    box-shadow: 0px 10px 20px rgba(0,0,0,0.1);
    border-radius: 6px;
    width: 280px;
    height: 300px;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
}

.jumbotron-fluid.shop:hover {
    background-color: white;
    margin:25px;
    box-shadow: 0px 10px 20px rgba(0,0,0,0.4);
    border-radius: 6px;
    width: 280px;
    height: 300px;
}

.jumbotron-fluid.article {
    background-color: white;
    margin:25px;
    box-shadow:0px 15px 20px rgba(0,0,0,0.1);
    border-radius: 15px;
}

.jumbotron-fluid.article:hover {
    background-color: white;
    margin:25px;
    box-shadow:0px 15px 20px rgba(0,0,0,0.4);
    border-radius: 15px;
}

.tagClass{
    cursor: pointer;
    color: blue; 
    text-decoration: none;
}

.open-icon{
    vertical-align:middle;
    float: right;
    color: rgb(19, 194, 19);
}

.close-icon{
    vertical-align:middle;
    float: right;
    color: rgb(207, 10, 10);
}
